export const tipoCiclo = {
  0: 'Semanal',
  1: 'Bissemanal',
  2: 'Mensal',
  3: 'Bimensal',
  4: 'Trimestral',
  5: 'Semestral',
  6: 'Anual',
} as const;

export type TipoCicloKeys = keyof typeof tipoCiclo;
